import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const NavigateButton = ({ to = '/grey-blending-handbook', imgSrc, imgAlt = '', className = '', children }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(to);
  };

  return (
    <div className={`navigate-button ${className}`} onClick={handleClick} style={{ cursor: 'pointer' }}>
      {imgSrc && <img src={imgSrc} alt={imgAlt} />}
      {children && <span>{children}</span>}
    </div>
  );
};

NavigateButton.propTypes = {
  to: PropTypes.string, // Path to navigate to
  imgSrc: PropTypes.string, // Optional image source
  imgAlt: PropTypes.string, // Alt text for the image
  className: PropTypes.string, // Optional class for styling
  children: PropTypes.node, // Optional content (text, icons, etc.)
};

export default NavigateButton;