import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const MobileNav = ({ closeNav }) => {
  return (
    <div className="mobile-nav">
      <div className="close-button" onClick={closeNav}>
        <span>&times;</span>
      </div>

      {/* Logo */}
      <div className="logo">
        <Link to="/">
          <img src="/images/lfhLogo.svg" alt="lfh logo" />
        </Link>
      </div>

      {/* Navigation Links */}
      <div className="mobile-nav-links">
        <a href="/services" onClick={closeNav}>Services</a>
        <a href="/grey-blending-handbook" onClick={closeNav}>Grey Blending</a>
        <a href="/about" onClick={closeNav}>About</a>
        <Link onClick={closeNav} to="/prices">Prices</Link>
        <a target='_blank' rel='noreferrer' href="https://sbs-hair.com/brands.html?acc=LizzieFoxHair" onClick={closeNav}>Products</a>
        <a href="/gallery" onClick={closeNav}>Gallery</a>
        <Link onClick={closeNav} to="/contact">Contact</Link>
        <Link onClick={closeNav} to="/olaplex">Olaplex</Link>
      </div>
    </div>
  );
};

const Nav = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const closeMobileNav = () => {
    setIsMobileNavOpen(false);
  };

  const whatsappLink = 'https://api.whatsapp.com/send?phone=447776111968';

  return (
    <nav>
      {/* Burger Menu Icon */}
      <div className='mobileNavMain'>
        <div className='mobileNavWhatsapp'>
          <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
            <img src='/images/whatsapp.png' alt='whatsapp logo' />
          </a>
        </div>
        <div className='mobileNavLogo'>
          <img src='/images/lfhlogo2.png' alt='lfh logo' />
        </div>
        <div className="burger-menu" onClick={toggleMobileNav}>
          <img src="/images/menu.png" alt="burger icon" />
        </div>
      </div>

      {/* Mobile Navigation */}
      {isMobileNavOpen && <MobileNav closeNav={closeMobileNav} />}

      {/* Regular Navigation Links */}
      <div className="nav-links">
        <Link
          to="/grey-blending-handbook">
          <a href="/grey-blending-handbook">Grey Blending</a>
        </Link>
        <a href="/about">About</a>
        <Link to="/prices">Prices</Link>
        <Link to="/">
          <img src="/images/lfhLogo.svg" alt="lfh logo" />
        </Link>
        <a target='_blank' rel='noreferrer' href="https://sbs-hair.com/brands.html?acc=LizzieFoxHair">Products</a>
        <a href="/gallery">Gallery</a>
        <Link to="/contact">Contact</Link>
      </div>
    </nav>
  );
};

export default Nav;
