import React, { useState, useEffect } from "react";
import axios from "axios";
import MainHero from "../components/MainHero";
import Nav from "../components/Nav";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEbookOpen, setIsEbookOpen] = useState(false); // Tracks eBook modal state

  const svgPages = Array.from({ length: 34 }, (_, index) => `images/ebook/${index + 1}.png`);

  // Check if the user is already logged in on component mount
  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  // Handle login
  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    try {
      const { data } = await axios.post(
        "https://lizzie-fox-hair.onrender.com/login",
        { email, password }
      );
      localStorage.setItem("authToken", data.token);
      setIsAuthenticated(true);
      setIsEbookOpen(true)
    } catch (err) {
      setErrorMessage("Invalid login credentials. Please try again.");
    }
  };

  return (
    <div>
      <Nav />
      <MainHero HeroTitle={"Log In"} height={"300px"} />
      {!isAuthenticated ? (
        // Login Form
        <form className="login-form" onSubmit={handleLogin}>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Login</button>
        </form>
      ) : (
        // Post-login: eBook Access
        <div className="open-ebook-btn">
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          <button
            onClick={() => setIsEbookOpen(true)}
          >
            Open eBook
          </button>

          {isEbookOpen && (
            // eBook Modal
            <div className="ebook-modal">
              <div className="modal-header">
                <button
                  className="close-btn"
                  onClick={() => setIsEbookOpen(false)}
                >
                  &times;
                </button>
              </div>
              <div className="ebook-pages">
                {svgPages.map((page, index) => (
                  <object
                    key={index}
                    data={page}
                    type="image/png"
                    className="ebook-page"
                    aria-label={`Page ${index + 1}`}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Login;