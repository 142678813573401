import React from 'react'

function TermsAndConditons() {
  return (
    <div className='termsPage'>
        <h1>The Grey Blending Handbook - Terms & Conditions</h1>

<p>Thank you for purchasing <strong>The Grey Blending Handbook</strong>. By completing your purchase, you agree to the following terms and conditions. Please read them carefully.</p>

<h2>1. Product Description</h2>
<p>
  <strong>Title:</strong> The Grey Blending Handbook
  <strong>Format:</strong> Online digital format via a login
  <strong>Content:</strong> The eBook provides expert advice and techniques on grey blending for hairstylists, including step-by-step guides, tips, and tutorials.
</p>

<h2>2. Pricing & Payment</h2>
<p>
  The price of the eBook is listed on the website. All payments are processed securely through <strong>Stripe</strong>.
</p>

<h2>3. Delivery</h2>
<p>
  Upon successful payment, you will receive login credentials for the Lizzie Fox Hair website via the email address you provided during purchase. There is no expiry on your access, but if you lose or forget your credentials, please contact <a href="mailto:info@lizziefoxhair.co.uk">info@lizziefoxhair.co.uk</a> for assistance with resetting your password or checking access.
</p>

<h2>4. Refund Policy</h2>
<p>
  Due to the nature of the digital product, we are unable to offer refunds once the product has been accessed or viewed via the website. If you experience any technical issues or believe the product is faulty, please contact us at <a href="mailto:info@lizziefoxhair.co.uk">info@lizziefoxhair.co.uk</a> for assistance.
</p>

<h2>5. Consumer Rights</h2>
<p>
  Under the <strong>Consumer Contracts Regulations</strong>, you have the right to cancel your purchase within 14 days of placing the order. However, by purchasing this eBook, you acknowledge that access to the product will be provided immediately, and as such, your right to cancel will be waived once the product has been accessed or viewed.
</p>

<h2>6. Usage Rights</h2>
<p>
  <strong>The Grey Blending Handbook</strong> is for <strong>personal use only</strong>. You may not:
  <ul>
    <li>Print the content</li>
    <li>Reproduce, redistribute, or sell the content in any form</li>
  </ul>
  All intellectual property rights related to the eBook remain with the author, Lizzie Fox.
</p>

<h2>7. Intellectual Property</h2>
<p>
  <strong>Copyright:</strong> All content within <strong>The Grey Blending Handbook</strong> is protected by copyright. You may not copy, modify, or distribute the content without explicit written permission from the author.
  <strong>Licensing:</strong> The eBook is licensed for personal use only.
</p>

<h2>8. Payment Security</h2>
<p>
  Payments are processed through <strong>Stripe</strong>, which complies with PCI-DSS security standards. Your payment information is encrypted and handled securely. If you experience any issues with your payment, please contact us directly at <a href="mailto:info@lizziefoxhair.co.uk">info@lizziefoxhair.co.uk</a>.
</p>

<h2>9. Support</h2>
<p>
  For technical support or assistance with accessing your eBook, please reach out to us at <a href="mailto:info@lizziefoxhair.co.uk">info@lizziefoxhair.co.uk</a>. We aim to respond to all inquiries within 48 hours.
</p>
    </div>
  )
}

export default TermsAndConditons