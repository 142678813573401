// App.js
import React from 'react';
import Nav from '../components/Nav';
import MainHero from '../components/MainHero';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Footer from '../components/Footer';
import '../App.css';
import BookButton from '../components/BookButton';
import CheckoutForm from "../components/CheckoutForm";

const stripePromise = loadStripe(
  "pk_live_51QbN3nHkl0IIwdOG8FWwTj49vzNpKAMswCXdSfZo36gy66hhfBlwIVRczFM37Mq87g0GC99kYt1tWDs7OtNvb7fv00wdZ7UAQP"
);

const Home = () => {
  return (
    <div className="app">
      <Nav />
      <BookButton />
      <MainHero HeroTitle={'Grey Blending Handbook'} height={'300px'} />
      <Elements stripe={stripePromise}>
        <CheckoutForm />
    </Elements>
      <Footer />
    </div>
  );
};

export default Home;
