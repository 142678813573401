import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useState } from 'react';
import Home from './pages/Home';
import ContactPage from './pages/Contact';
import PricesPage from './pages/Prices';
import Olaplex from './pages/Olaplex';
import Products from './pages/Products';
import Services from './pages/Services';
import Gallery from './pages/Gallery';
import About from './pages/About';
import Purchase from './pages/Purchase';
import GreyBlendingHandbook from './pages/GreyBlendingHandbook';
import Login from './pages/Login';
import CheckoutForm from './components/CheckoutForm';
import Register from './pages/Register';
import Terms from './pages/Terms';

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);

if (authenticated) {
  console.log('User authenticated');
}

  return (
    <Router>
      <Routes>
        <Route index element={<Home />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="prices" element={<PricesPage />} />
        <Route path="olaplex" element={<Olaplex />} />
        <Route path="products" element={<Products />} />
        <Route path="services" element={<Services />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="about" element={<About />} />
        <Route path="purchase" element={<Purchase />} />
        <Route path="grey-blending-handbook" element={<GreyBlendingHandbook />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route
          path="checkout"
          element={<CheckoutForm setAuthenticated={setAuthenticated} />}
        />
        <Route path="terms" element={<Terms />} />
      </Routes>
    </Router>
  );
};

export default App;