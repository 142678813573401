import React from "react";
import NavigateButton from "./NavigateButton";

function GreyBlendingBanner() {
  return (
    <div className="grey-blending-banner">
      {/* <NavigateButton className="option text-only">My new grey blending handbook - available now!</NavigateButton> */}
      <NavigateButton style={{cursor: 'unset'}} to="/grey-blending-handbook" className="option text-only"><span style={{padding: '20px'}}>My new grey blending handbook is now available - <span style={{textDecoration: 'underline'}}>learn more</span></span></NavigateButton>
    </div>
  );
}

export default GreyBlendingBanner;
