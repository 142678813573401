// Gallery.js
import React from 'react';

const Gallery = () => {
  return (
    <div className="gallery">
      <img src="/images/gallery1.jpg" alt="Lady with her hair stayled" />
      <img src="/images/gallery2.jpg" alt="Lady with her hair stayled" />
      <img src="/images/gallery3.jpg" alt="Lady with her hair stayled" />
      <img src="/images/gallery4.jpg" alt="Lady with her hair stayled" />
      <img src="/images/gallery5.jpg" alt="Lady with her hair stayled" />
      <img src="/images/gallery6.jpg" alt="Lady with her hair stayled" />
      <img src="/images/gallery7.jpg" alt="Lady with her hair stayled" />
      <img src="/images/gallery8.jpg" alt="Lady with her hair stayled" />
      <img src="/images/gallery9.jpg" alt="Lady with her hair stayled" />
      <img src="/images/gallery10.jpg" alt="Lady with her hair stayled" />
      <img src="/images/gallery11.jpg" alt="Lady with her hair stayled" />
      <img src="/images/gallery12.jpg" alt="Lady with her hair stayled" />
      <img src="/images/gallery13.jpg" alt="Lady with her hair stayled" />
      <img src="/images/gallery14.jpg" alt="Lady with her hair stayled" />
      <img src="/images/gallery15.jpg" alt="Lady with her hair stayled" />
      <img src="/images/gallery16.jpg" alt="Lady with her hair stayled" />
      <img src="/images/gallery17.jpg" alt="Lady with her hair stayled" />
      <img src="/images/gallery18.jpg" alt="Lady with her hair stayled" />
      <img src="/images/gallery19.jpg" alt="Lady with her hair stayled" />
    </div>
  );
};

export default Gallery;
