import React, { useState } from "react";
// import NavigateButton from "./NavigateButton";

const BookButton = () => {
  const emailAddress = "lizzie@lizziefoxhair.com";
  const subject = "Website Booking Inquiry";
  const encodedSubject = encodeURIComponent(subject);
  const mailtoLink = `mailto:${emailAddress}?subject=${encodedSubject}`;
  const whatsappLink = "https://api.whatsapp.com/send?phone=447776111968";
  const instagramLink = "https://www.instagram.com/lizziefoxhair/";

  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`bookButton ${isOpen ? "open" : ""}`}
      onClick={handleButtonClick}
    >
      <div className="option email">
        <a href={mailtoLink}>
          <img src="/images/mail.png" alt="Email" />
        </a>
      </div>
      <div className="option whatsapp">
        <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
          <img src="/images/whatsapp2.png" alt="WhatsApp" />
        </a>
      </div>
      <div className="option instagram">
        <a href={instagramLink} target="_blank" rel="noopener noreferrer">
          <img src="/images/instagram.png" alt="Instagram" />
        </a>
      </div>
        {/* <NavigateButton
          imgSrc="/images/handbook.png"
          imgAlt="Grey blending icon"
          className="option greyblending"
          to="/#"
        /> */}
      <span>Book with Lizzie</span>
    </div>
  );
};

export default BookButton;

// import React from 'react';

// const BookButton = () => {
//   const emailAddress = 'lizzie@lizziefoxhair.com';
//   const subject = 'Website Booking Inquiry';

//   const encodedSubject = encodeURIComponent(subject);

//   const mailtoLink = `mailto:${emailAddress}?subject=${encodedSubject}`;

//   return (
//     <a href={mailtoLink} className="bookButton">
//       Book with Lizzie
//     </a>
//   );
// };

// export default BookButton;
